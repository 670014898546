import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from 'src/shared/theme';
import { pageSideMargin } from 'src/shared/styles/page-margin';
import { XS } from 'src/shared/media-query';
import HomeIcon from '../../../../static/assets/house.svg';

interface Props {
  className?: string;
  crumbs: Crumb[];
}

export interface Crumb {
  pathname: string;
  crumbLabel: string;
}

const Wrapper = styled.div`
  ${pageSideMargin};
`;

const Container = styled(Breadcrumbs)`
  border: 1px solid rgba(2, 33, 79, 0.08);
  color: rgba(2, 33, 79, 0.08);
  border-left: 0;
  border-right: 0;
  margin-top: ${theme.spacing(2)};

  & > ol {
    flex-wrap: nowrap;
    overflow: hidden;

    & > li {
      &[aria-hidden] {
        margin: ${theme.spacing(2)} ${theme.spacing(2)};

        @media ${XS} {
          margin: ${theme.spacing(2)} ${theme.spacing(5)};
        }
      }

      display: flex;
      align-items: center;

      & > a {
        
      }
    }
  }
`;

const Item = styled(Link)`
  display: flex;
`;

const Icon = styled(HomeIcon)`
  margin-right: ${theme.spacing(1)};
`;

const Breadcrumb: FC<Props> = (props) => (
  <Wrapper>
    <Container separator="|">
      <Item color="textPrimary" to="/" key="0">
        <Icon css={css`:hover {color: ${theme.palette.primary.main};}`} />
      </Item>
      {props.crumbs
        .filter((crumb) => crumb.pathname !== `/`)
        .map((crumb, i, array) => (
          <Item
            color={i === array.length - 1 ? `textSecondary` : `textPrimary`}
            to={crumb.pathname}
            key={crumb.crumbLabel}
            variant="caption"
          >
            {crumb.crumbLabel}
          </Item>
        ))}
    </Container>
  </Wrapper>
);

export default Breadcrumb;
